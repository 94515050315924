// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-brigade__header, .table-brigade__body {
  width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/components/TableBrigadeCalendar/style.scss"],"names":[],"mappings":"AAEI;EACI,YAAA;AADR","sourcesContent":[".table-brigade {\n    \n    &__header, &__body {\n        width: 200px;\n        \n        \n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
