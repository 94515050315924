// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ordermaterialslist__filter {
  margin-top: 15px;
  display: flex;
}
.ordermaterialslist__filter-item {
  font-size: 19px;
  font-weight: 500;
  color: rgb(7, 7, 7);
  padding-right: 10px;
  cursor: pointer;
}
.ordermaterialslist__filter-item.active {
  color: #ff0000;
}

.projectmaterial__top {
  margin-top: 25px;
  display: flex;
}
.projectmaterial__number {
  font-size: 22px;
  color: rgb(0, 75, 214);
  font-weight: 700;
  padding-right: 10px;
}
.projectmaterial__project {
  font-size: 22px;
  color: rgb(0, 75, 214);
  font-weight: 700;
  white-space: nowrap;
}

.materialproject__top {
  margin-top: 25px;
  display: flex;
}
.materialproject__name {
  font-size: 22px;
  color: rgb(0, 75, 214);
  font-weight: 700;
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/components/OrderMaterialsList/OrderMaterialsList.styles.scss"],"names":[],"mappings":"AAEI;EACI,gBAAA;EACA,aAAA;AADR;AAGQ;EACI,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;AADZ;AAGY;EACI,cAAA;AADhB;;AAQI;EACI,gBAAA;EACA,aAAA;AALR;AAOI;EACI,eAAA;EACA,sBAAA;EACA,gBAAA;EACA,mBAAA;AALR;AAOI;EACI,eAAA;EACA,sBAAA;EACA,gBAAA;EACA,mBAAA;AALR;;AAWI;EACI,gBAAA;EACA,aAAA;AARR;AAUI;EACI,eAAA;EACA,sBAAA;EACA,gBAAA;EACA,mBAAA;AARR","sourcesContent":[".ordermaterialslist {\n   \n    &__filter {\n        margin-top: 15px;\n        display: flex;\n\n        &-item {\n            font-size: 19px;\n            font-weight: 500;\n            color: rgb(7,7,7);\n            padding-right: 10px;\n            cursor: pointer;\n           \n            &.active {\n                color: #ff0000;\n            }\n        }\n    }\n}\n\n.projectmaterial {\n    &__top {\n        margin-top: 25px;\n        display: flex;\n    }\n    &__number {\n        font-size: 22px;\n        color: rgb(0, 75, 214);\n        font-weight: 700;\n        padding-right: 10px;\n    }\n    &__project {\n        font-size: 22px;\n        color: rgb(0, 75, 214);\n        font-weight: 700;\n        white-space: nowrap;\n        \n    }\n}\n\n.materialproject {\n    &__top {\n        margin-top: 25px;\n        display: flex;\n    }\n    &__name {\n        font-size: 22px;\n        color: rgb(0, 75, 214);\n        font-weight: 700;\n        white-space: nowrap;\n        \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
