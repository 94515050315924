// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.complaint-installation__thead {
  text-align: center;
}
.complaint-installation__button {
  color: black;
  font-size: 16px;
  font-weight: 600;
  padding: 0px;
}
@media (max-width: 460px) {
  .complaint-installation__button {
    font-size: 13px;
  }
}

.complaint-image__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.complaint-image__image {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
}
.complaint-image__image img {
  width: 350px;
  height: 250px;
  margin-bottom: 10px;
}
@media (max-width: 410px) {
  .complaint-image__image img {
    width: 330px;
    height: 220px;
  }
}
@media (max-width: 410px) {
  .complaint-image__image img {
    width: 330px;
    height: 220px;
  }
}
@media (max-width: 390px) {
  .complaint-image__image img {
    width: 300px;
    height: 200px;
  }
}
@media (max-width: 350px) {
  .complaint-image__image img {
    width: 280px;
    height: 180px;
  }
}
.complaint-image__delete {
  font-size: 18px;
  font-weight: 400;
  color: red;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/InstallationPage/ComplaintInstallation/style.scss"],"names":[],"mappings":"AAGI;EACI,kBAAA;AAFR;AAKI;EACI,YAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;AAHR;AAIQ;EALJ;IAMQ,eAAA;EADV;AACF;;AAQI;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;AALR;AAQI;EACG,eAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AANP;AAOO;EACK,YAAA;EACA,aAAA;EACA,mBAAA;AALZ;AAMY;EAJL;IAKS,YAAA;IACA,aAAA;EAHd;AACF;AAIY;EARL;IASS,YAAA;IACA,aAAA;EADd;AACF;AAEY;EAZL;IAaS,YAAA;IACA,aAAA;EACd;AACF;AAAY;EAhBL;IAiBS,YAAA;IACA,aAAA;EAGd;AACF;AAGI;EACI,eAAA;EACA,gBAAA;EACA,UAAA;EACA,eAAA;AADR","sourcesContent":["//table\n.complaint-installation {\n\n    &__thead {\n        text-align: center;\n    }\n\n    &__button {\n        color: black;\n        font-size: 16px;\n        font-weight: 600;\n        padding: 0px;\n        @media(max-width: 460px) {\n            font-size: 13px;\n        }\n    }\n}\n\n\n// image\n.complaint-image {\n    &__content {\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n    }\n    \n    &__image {\n       cursor: pointer;\n       display: flex;\n       flex-direction: column;\n       justify-content: center;\n       margin-bottom: 30px;\n       img {\n            width: 350px;\n            height: 250px;\n            margin-bottom: 10px;\n            @media(max-width: 410px) {\n                width: 330px;\n                height: 220px;\n            }\n            @media(max-width: 410px) {\n                width: 330px;\n                height: 220px;\n            }\n            @media(max-width: 390px) {\n                width: 300px;\n                height: 200px;\n            }\n            @media(max-width: 350px) {\n                width: 280px;\n                height: 180px;\n            }\n       }\n        \n        \n    }\n\n    &__delete {\n        font-size: 18px;\n        font-weight: 400;\n        color: red;\n        cursor: pointer;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
