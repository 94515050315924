import React from 'react';
import ComplaintList from '../components/Complaint/ComplaintList';

function Complaint() {
  return (
    <>
      <ComplaintList />
    </>
  );
}

export default Complaint;
