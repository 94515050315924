// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-name, .project-sum {
  height: 40px;
}

.brigade-name, .brigade-sum {
  height: 40px;
}

.project-name {
  cursor: pointer;
}

.brigade-name {
  margin-top: 20px;
}

.brigade-sum {
  margin-top: 20px;
}

.distribution__thead {
  font-size: 18px;
  color: rgb(7, 7, 7);
  font-weight: 700;
  text-align: center;
  width: 120px;
}
@media (max-width: 460px) {
  .distribution__thead {
    font-size: 12px;
    width: 50px;
  }
}

.distribution__thead-project {
  font-size: 18px;
  color: rgb(7, 7, 7);
  font-weight: 700;
  text-align: center;
  width: 180px;
}
@media (max-width: 460px) {
  .distribution__thead-project {
    font-size: 12px;
    width: 100px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Distribution/style.scss"],"names":[],"mappings":"AAEA;EACI,YAAA;AADJ;;AAIA;EACG,YAAA;AADH;;AAIA;EACI,eAAA;AADJ;;AAIA;EACI,gBAAA;AADJ;;AAKA;EACI,gBAAA;AAFJ;;AAMA;EACI,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,YAAA;AAHJ;AAII;EANJ;IAOQ,eAAA;IACA,WAAA;EADN;AACF;;AAIA;EACI,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,YAAA;AADJ;AAEI;EANJ;IAOQ,eAAA;IACA,YAAA;EACN;AACF","sourcesContent":["\n\n.project-name, .project-sum {\n    height: 40px;\n}\n\n.brigade-name, .brigade-sum {\n   height: 40px;\n}\n\n.project-name {\n    cursor: pointer;\n}\n\n.brigade-name {\n    margin-top: 20px;\n  \n}\n\n.brigade-sum {\n    margin-top: 20px;\n}\n\n\n.distribution__thead {\n    font-size: 18px;\n    color: rgb(7,7,7);\n    font-weight: 700;\n    text-align: center;\n    width: 120px;\n    @media(max-width: 460px) {\n        font-size: 12px;\n        width: 50px;\n    }\n}\n\n.distribution__thead-project {\n    font-size: 18px;\n    color: rgb(7,7,7);\n    font-weight: 700;\n    text-align: center;\n    width: 180px;\n    @media(max-width: 460px) {\n        font-size: 12px;\n        width: 100px;\n    }\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
